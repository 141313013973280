import React, {useState} from "react";
import styled from "@emotion/styled";
import spinner from "../resources/loader.gif";

const Loader = () => {
    return (
        <LoaderContainer>
            <LoaderImg src={spinner}/>
        </LoaderContainer>
    );
};

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 0.5;
  z-index: 100;
`;

const LoaderImg = styled.img`
  position: absolute;
`;

const useLoader = () => {
    const [loading, setLoading] = useState(false);
    return [loading ? <Loader/> : null, () => setLoading(true), () => setLoading(false)] as const;
};

export default useLoader;
