import {styled} from "@mui/material/styles";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {useLayoutContext} from "../hooks/use-layout-context.hook";
import {configo} from "../libs/configo.functions";

type AppBarProps = MuiAppBarProps & {
    open?: boolean;
    drawerwidth: number;
}

const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== "open"
})<AppBarProps>(({theme, open, drawerwidth}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerwidth,
        width: `calc(100% - ${drawerwidth - 33}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

export default function AppBar() {
    const {
        state: {open, drawerwidth, title},
        dispatch
    } = useLayoutContext();
    return (
        <StyledAppBar position="fixed" open={open} drawerwidth={drawerwidth}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => dispatch({type: "set-drawer", open: !open})}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && {display: "none"})
                    }}>
                    <MenuIcon/>
                </IconButton>
                <Typography variant="h6" noWrap component="div">{title}</Typography>
                <Box sx={{flexGrow: 1}}/>
                <Typography variant="h6" noWrap component="div">{configo("version")}</Typography>
            </Toolbar>
        </StyledAppBar>
    );
}