export enum instructionActions {
    ACCEPT = "ACCEPT",
    IGNORE = "IGNORE",
    SCHEDULE = "SCHEDULE"
}

export enum packageType {
    SYSTEM = "SYSTEM",
    BUSINESS_MODEL = "BUSINESS_MODEL",
    TREATMENT_LEVEL = "TREATMENT_LEVEL",
    ACCESSORY = "ACCESSORY"
}

export enum instructionType {
    OPTIONAL = "OPTIONAL",
    MANDATORY = "MANDATORY"
}

export enum criticality {
    CRITICAL = "CRITICAL",
    IMPORTANT = "IMPORTANT",
    NORMAL = "NORMAL"
}
