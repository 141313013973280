import assert from "assert";

let configData: any = null;

export function configo(name: string): string {
    if (configData === null) {
        const configFile = require("../resources/config.json");
        configData = configFile.stages[configFile.stage];
        configData["version"] = configFile.version;
        configData["stage"] = configFile.stage;
    }
    assert(configData);
    return configData[name];
}

// noinspection JSUnusedGlobalSymbols
export default configData;
