import * as React from "react";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {Divider, Paper} from "@mui/material";
import {Grid} from "@material-ui/core";

// noinspection DuplicatedCode
const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function ViewBulletin(props: any) {
    const {open, setOpen, bulletin} = props;

    const handleClose = () => {
        setOpen(false);
    };
    if (bulletin)
        return (
            <Dialog fullWidth onClose={handleClose} style={{}} open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Bulletin: {bulletin.bulletin.bulletinId}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography fontWeight={"bold"}>Description:</Typography>
                    <Typography gutterBottom>{bulletin.bulletin.description}</Typography>
                    <Divider/>
                    <Typography marginTop={2} fontWeight={"bold"}>
                        Criticality:
                    </Typography>
                    <Typography gutterBottom>{bulletin.bulletin.criticality}</Typography>
                    <Divider/>
                    <Typography marginTop={2} fontWeight={"bold"}>
                        Record User Response:
                    </Typography>
                    <Typography gutterBottom>{bulletin.bulletin.recordUserResponse ? "Yes" : "No"}</Typography>
                    <Divider/>
                    <Typography marginTop={2} fontWeight={"bold"}>
                        Base Versions:
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Item style={{backgroundColor: "#1976d2", color: "white", fontWeight: "bolder"}}>Part
                                Number</Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item style={{backgroundColor: "#1976d2", color: "white", fontWeight: "bolder"}}>Package
                                Type</Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item style={{backgroundColor: "#1976d2", color: "white", fontWeight: "bolder"}}>Part
                                Revision</Item>
                        </Grid>
                    </Grid>
                    {bulletin.bulletin.baseVersions.map((_obj: any, index: number) => (
                        <Grid key={index} container spacing={2}>
                            <Grid item xs={4}>
                                <Item>{_obj.partNumber}</Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>{_obj.packageType}</Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>{_obj.partRevision}</Item>
                            </Grid>
                        </Grid>
                    ))}
                    <Typography marginTop={2} fontWeight={"bold"}>
                        Instructions:
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Item style={{
                                backgroundColor: "#1976d2",
                                color: "white",
                                fontWeight: "bolder"
                            }}>Message</Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item style={{backgroundColor: "#1976d2", color: "white", fontWeight: "bolder"}}>Type</Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item style={{
                                backgroundColor: "#1976d2",
                                color: "white",
                                fontWeight: "bolder"
                            }}>Actions</Item>
                        </Grid>
                    </Grid>
                    {bulletin.bulletin.instructions.map((_obj: any, index: number) => (
                        <Grid key={index} container spacing={2}>
                            <Grid item xs={4}>
                                <Item>{_obj.message}</Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>{_obj.type}</Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>{_obj.actions.toString()}</Item>
                            </Grid>
                        </Grid>
                    ))}
                </DialogContent>
                {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
            </Dialog>
        );
    return null;
}
