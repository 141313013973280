import * as React from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ViewBulletin from "../viewBulletin";
import ViewConsoles from "../viewConsoles";
import {deleteBulletinService} from "../bulletin.service";
import MUIDataTable from "mui-datatables";
import useLoader from "../../../hooks/useLoader";

const columns = [
    "Bulletin ID", "Description", "Criticality", "Record User Response?", "Assgiend to Consoles", "Actions"
];

export default function StickyHeadTable(props: any): JSX.Element {
    const [loader, showLoader, hideLoader] = useLoader();
    const {bulletins, loadBulletins} = props;
    const [openView, setOpenView] = React.useState(false);
    const [openConsoleView, setOpenConsoleView] = React.useState(false);
    const [bulletin, setBulletin] = React.useState();

    const datatableDataMapper = (data: any[]) => {
        const result: any = [];
        if (data.length > 0)
            data.forEach((element) => {
                const {bulletin} = element;
                let assignedConsoles = 0;
                if (Array.isArray(element.consoleIds)){
                    assignedConsoles = element.consoleIds.length;
                }
                const test = [
                    bulletin.bulletinId,
                    bulletin.description,
                    bulletin.criticality,
                    bulletin.recordUserResponse ? "Yes" : "No",
                    assignedConsoles,
                    <>
                        <IconButton onClick={() => deleteBulletin(element)} aria-label="delete" color="primary"
                                    disabled={(assignedConsoles > 0)}>
                            <DeleteIcon/>
                        </IconButton>
                        <IconButton onClick={() => viewBulletin(element)} color="secondary" aria-label="View Bulletin">
                            <VisibilityOutlinedIcon/>
                        </IconButton>
                        <IconButton onClick={() => viewConsoles(element)} color="primary" aria-label="View Consoles">
                            <ListAltOutlinedIcon/>
                        </IconButton>
                    </>
                ];
                result.push(test);
            });
        return result;
    };

    const deleteBulletin = async (element: any) => {
        if (Array.isArray(element.consoleIds) && element.consoleIds.length > 0){
            alert("Bulletin is assinged to consoles, and cannot be deleted.");
        }
        else if (Array.isArray(element.consoleIds) && element.consoleIds.length === 0) {
            let bulletinId: string = element.bulletin.bulletinId;
            showLoader();
            const response = await deleteBulletinService(bulletinId);
            hideLoader();
            if (response.status) {
                loadBulletins(true);
            } else {
                alert(response.result);
            }
        }
    };

    const viewBulletin = (bulletin: any) => {
        setBulletin(bulletin);
        setOpenView(true);
    };

    const viewConsoles = (bulletin: any) => {
        setBulletin(bulletin);
        setOpenConsoleView(true);
    };

    const options = {
        caseSensitive: false,
        confirmFilters: false,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 100],
        selectableRows: undefined,
    };

    return (
        <Paper sx={{width: "100%", overflow: "hidden"}} elevation={12}>
            {loader}
            <MUIDataTable
                title={"Bulletins List"}
                data={datatableDataMapper(bulletins)}
                columns={columns}
                options={options}
            />
            <ViewBulletin open={openView} setOpen={setOpenView} bulletin={bulletin}/>
            <ViewConsoles
                open={openConsoleView}
                setOpen={setOpenConsoleView}
                bulletin={bulletin}
                loadBulletins={loadBulletins}
            />
        </Paper>
    );
}



