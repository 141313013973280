import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import {useLayoutContext} from "../hooks/use-layout-context.hook";
import {ReactElement} from "react";
import ListItemButton from "@mui/material/ListItemButton";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import Home from "../routes/home";
import Bulletin from "../routes/bulletin/bulletin";

export type NavBarItem = {
    path: string
    descendant?: boolean // path has descendants, if ture appends path with '/*'
    icon: ReactElement<any, any>
    label: string
    element: ReactElement<any, any>
    divider?: boolean // place a divider after the link
}

const SideDrawer = () => {
    const {
        state: {open},
        dispatch
    } = useLayoutContext();

    const navigate = useNavigate();

    const navigation = (path: string) => {
        navigate(path);
        dispatch({type: "set-drawer", open: false});
    };

    const toggleDrawer = () => {
        dispatch({type: "set-drawer", open: !open});
    };

    const fontSize = undefined;

    const configs: NavBarItem[] = [
        {
            label: "Dashboard",
            icon: <DashboardIcon sx={{fontSize}}/>,
            path: "/",
            element: <Home/>,
            descendant: true
        },
        {
            label: "Bulletin",
            icon: <AutoAwesomeMotionIcon sx={{fontSize}}/>,
            path: "bulletin",
            element: <Bulletin/>,
            descendant: true
        },
        // {
        //   label: 'Console',
        //   icon: <BookOnlineIcon sx={{ fontSize }} />,
        //   path: 'console',
        //   element: <Console />,
        //   descendant: true,
        // },
    ];

    return (
        <Drawer anchor={"left"} open={open} onClose={toggleDrawer}>
            <div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                    <IconButton onClick={() => toggleDrawer()}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    {configs.map((item) => (
                        <ListItemButton
                            key={item.label}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5
                            }}
                            onClick={() => navigation(item.path)}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center"
                                }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.label} sx={{opacity: open ? 1 : 0}}/>
                        </ListItemButton>
                    ))}
                </List>
            </div>
        </Drawer>
    );
};

export default SideDrawer;
