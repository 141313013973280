import {useContext} from "react";

import {useNavigate} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import {AuthContext} from "../contexts/authContext";
import {clearCache} from "../libs/cache.functions";


const useStyles = makeStyles(() => ({
    root: {},
    title: {
        textAlign: "center"
    },
    session: {
        width: "80vw",
        overflow: "auto",
        overflowWrap: "break-word",
        fontSize: "16px"
    },
    hero: {
        width: "100%",
        background: "rgb(220,220,220)"
    }
}));

export default function Home() {
    const classes = useStyles();
    const navigate = useNavigate();
    const auth = useContext(AuthContext);

    function signOutClicked() {
        auth.signOut();
        clearCache();
        navigate("/");
    }

    return (
        <Grid container>
            <Grid className={classes.root} container direction="column" justifyContent="center" alignItems="center">
                <Box className={classes.hero} p={4}>
                    <Grid className={classes.root} container direction="column" justifyContent="center"
                          alignItems="center">
                        <Box m={2}>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Typography className={classes.title} variant="h3">
                                    Dashboard Home
                                </Typography>
                            </Grid>
                        </Box>
                        <Box m={2}>
                            <Button onClick={signOutClicked} variant="contained" color="primary">
                                Sign Out
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}
