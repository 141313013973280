import React from "react";

import {useNavigate} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {configo} from "../libs/configo.functions";

const useStyles = makeStyles(() => ({
    root: {
        height: "100vh"
    },
    title: {
        textAlign: "center"
    }
}));

const Landing: React.FunctionComponent = () => {
    const classes = useStyles();

    const navigate = useNavigate();

    const signIn = () => {
        navigate("/signin");
    };

    return (
        <Grid container>
            <Grid className={classes.root} container direction="column" justifyContent="center" alignItems="center">
                <Box m={2}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Typography className={classes.title} variant="h3">
                            Console Dashboard
                        </Typography>
                    </Grid>
                </Box>
                <Box m={2}>
                    <Button onClick={signIn} variant="contained" color="primary">
                        SIGN IN
                    </Button>
                </Box>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">{configo("version")}</Typography>
                </Toolbar>
            </Grid>
        </Grid>
    );
};

export default Landing;
