import * as React from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ViewBulletin from "../viewBulletin";
import MUIDataTable from "mui-datatables";

const columns = ["Console ID", "Actions"];

export default function StickyHeadTable(props: any): JSX.Element {
    const {consoles} = props;
    const [consoleId, setConsoleId] = React.useState<string>("");
    const [openView, setOpenView] = React.useState(false);

    const datatableDataMapper = (data: any[]) => {
        const result: any = [];
        if (data.length > 0)
            data.forEach((b) => {
                const test = [
                    b.consoleId,
                    <IconButton onClick={() => viewBulletin(b.consoleId)} color="primary" aria-label="View Consoles">
                        <ListAltOutlinedIcon/>
                    </IconButton>
                ];
                result.push(test);
            });
        return result;
    };

    const viewBulletin = (_id: string) => {
        setConsoleId(_id);
        setOpenView(true);
    };

    const options = {
        caseSensitive: false,
        confirmFilters: false,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 100],
        selectableRows: undefined,
    };

    return (
        <Paper sx={{width: "50%", overflow: "hidden", margin: "auto"}} elevation={12}>
            <MUIDataTable
                title={"Consoles List"}
                data={datatableDataMapper(consoles)}
                columns={columns}
                options={options}
            />
            <ViewBulletin open={openView} consoleId={consoleId} setOpen={setOpenView}/>
        </Paper>
    );
}
