import React, {useEffect, useReducer} from "react";
import "./App.css";

import {Route, Routes} from "react-router-dom";

import {createTheme, responsiveFontSizes, ThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import AuthProvider, {AuthIsNotSignedIn, AuthIsSignedIn} from "./contexts/authContext";

import SignIn from "./routes/auth/signIn";
import SignUp from "./routes/auth/signUp";
import VerifyCode from "./routes/auth/verify";
import RequestCode from "./routes/auth/requestCode";
import ForgotPassword from "./routes/auth/forgotPassword";
import ChangePassword from "./routes/auth/changePassword";
import Landing from "./routes/landing";
import Home from "./routes/home";
import {drawerReducer, LayoutContext} from "./contexts/layoutContext";
import Box from "@mui/material/Box";
import SideDrawer from "./components/drawer.component";
import AppBar from "./../src/components/app-bar.component";
import Bulletin from "./routes/bulletin/bulletin";
import AddBulletin from "./routes/bulletin/addBulletin";
import Console from "./routes/console/console";
import useLoader from "./hooks/useLoader";
import {fetchCached} from "./libs/cache.functions";
import {getAllConsolesService} from "./routes/console/consoles.service";

let lightTheme = createTheme({
    palette: {
        type: "light"
    }
});
lightTheme = responsiveFontSizes(lightTheme);

// let darkTheme = createTheme({
//   palette: {
//     type: "dark",
//   },
// })
// darkTheme = responsiveFontSizes(darkTheme)

const SignInRoute: React.FunctionComponent = () => (

    <Routes>
        <Route path="/signin" element={<SignIn/>}/>
        <Route path="/signup" element={<SignUp/>}/>
        <Route path="/verify" element={<VerifyCode/>}/>
        <Route path="/requestCode" element={<RequestCode/>}/>
        <Route path="/forgotPassword" element={<ForgotPassword/>}/>
        <Route path="/" element={<Landing/>}/>
        <Route path="/home" element={<Home/>}/>
    </Routes>

);

const MainRoute: React.FunctionComponent = () => {
    const [, showLoader, hideLoader] = useLoader();
    useEffect(() => {
        loadConsolesAndIds().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadConsolesAndIds() {
        showLoader();
        let consoles = await fetchCached("consoles", getAllConsolesService, "consoles");
        if (!consoles) {
            consoles = [];
        }
        let consoleIds = consoles.reduce((a: string[], currentValue: any) => [...a, currentValue.consoleId], []);
        dispatch({type: "set-consoles", consoles: consoleIds});
        hideLoader();
    }

    const [state, dispatch] = useReducer(drawerReducer, {
        open: false,
        title: "Console Dashboard",
        drawerwidth: 205,
        consoles: []
    });
    return (
        <LayoutContext.Provider value={{state, dispatch}}>
            <Box sx={{display: "flex"}}>
                <CssBaseline/>
                <AppBar/>
                <SideDrawer/>
                <Box component="main" sx={{flexGrow: 1, pt: 10, pl: 5}}>
                    <Routes>
                        <Route path="/changePassword" element={<ChangePassword/>}/>
                        <Route path="/bulletin/addBulletin" element={<AddBulletin/>}/>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/bulletin" element={<Bulletin/>}/>
                        <Route path="/console" element={<Console/>}/>
                    </Routes>
                </Box>
            </Box>
        </LayoutContext.Provider>
    );
};

const App: React.FunctionComponent = () => {

    return (
        <ThemeProvider theme={lightTheme}>
            <CssBaseline/>
            <AuthProvider>
                <AuthIsSignedIn>
                    <MainRoute/>
                </AuthIsSignedIn>
                <AuthIsNotSignedIn>
                    <SignInRoute/>
                </AuthIsNotSignedIn>
            </AuthProvider>
        </ThemeProvider>
    );
};

export default App;
