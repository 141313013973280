import {Bulletin} from "./bulletin.types";

export const required = (value: string, field: string) => {
    if (value.trim() === "") {
        return `${field} is required`;
    }
    return "";
};
export const arrayRequired = (value: any, field: string) => {
    if (value.length === 0) {
        return `${field} is required`;
    }
    return "";
};

export const lengthCheck = (value: string, field: string, minLength: number) => {
    if (value.trim().length < minLength) {
        return `${field} minimum length is ${minLength}`;
    }
    return "";
};

export const validateForm = (formInput: Bulletin) => {
    const error: string[] = [];
    const descCheck = required(formInput.description, "Description");
    if (descCheck !== "") {
        error.push(descCheck);
    }
    const criticalityCheck = required(formInput.criticality, "Criticality");
    if (criticalityCheck !== "") {
        error.push(criticalityCheck);
    }
    formInput.baseVersions.forEach((row, index) => {
        if (row.isRequired) {
            const partNumberCheck = required(row.partNumber, `Line ${index + 1}: Part Number`);
            if (partNumberCheck !== "") {
                error.push(partNumberCheck);
            }
            const partNumberLengthCheck = lengthCheck(row.partNumber, `Line ${index + 1}: Part Number`, 3);
            if (partNumberLengthCheck !== "") {
                error.push(partNumberLengthCheck);
            }
            const partRevisionCheck = required(row.partRevision, `${index + 1
            }: Part Revision`);
            if (partRevisionCheck !== "") {
                error.push(partRevisionCheck);
            }
            const partRevisionLengthCheck = lengthCheck(row.partRevision, `Line ${index + 1}: Part Revision`, 1);
            if (partRevisionLengthCheck !== "") {
                error.push(partRevisionLengthCheck);
            }
            const packageTypeCheck = required(row.packageType, `Line ${index + 1}: Package Type`);
            if (packageTypeCheck !== "") {
                error.push(packageTypeCheck);
            }
        }
    });
    formInput.instructions.forEach((row, index) => {
        const messageCheck = required(row.message, `Line ${index + 1}: Instructions Message`);
        if (messageCheck !== "") {
            error.push(messageCheck);
        }
        const typeCheck = required(row.type, `Line ${index + 1}: Instructions Revision`);
        if (typeCheck !== "") {
            error.push(typeCheck);
        }
        const actionsCheck = arrayRequired(row.actions, `Line ${index + 1}: Instructions Type`);
        if (actionsCheck !== "") {
            error.push(actionsCheck);
        }
    });
    return error;

};