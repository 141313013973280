import {configo} from "./configo.functions";
import axios from "axios";
import * as cognito from "./cognito.functions";
import {CognitoUserSession} from "amazon-cognito-identity-js";

export type ApiResponse = {
    status: boolean;
    result: any;
}

function responseMapper(payload: ApiResponse): ApiResponse {
    let response = payload;
    if (payload.result) {
        if (payload.result.statusCode === 20200) {
            return response;
        } else {
            response.status = false;
        }
    } else {
        payload.status = false;
        payload.result = "No data Found";
    }
    return response;
}

export async function callRestApi(path: string, payload: any): Promise<ApiResponse> {
    let session: CognitoUserSession;
    try {
        // @ts-ignore
        session = await cognito.getSession();
    } catch (err) {
        return responseMapper({status: false, result: Error("Not Signed In")});
    }

    if (!session.isValid()) {
        return responseMapper({status: false, result: Error("Session Invalid")});
    }

    const API_KEY = configo("REACT_APP_API_KEY");
    const HOST = configo("REACT_APP_HOST");

    const headers = {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        "Authorization": session.getAccessToken().getJwtToken(),
    };

    const request: any = {
        method: "post",
        url: `${HOST}/${path}`,
        headers: headers,
        data: payload
    };

    let response: ApiResponse = responseMapper({status: false, result: Error("This should have never happened")});
    await axios(request)
        .then(function (resp: any) {
            response = responseMapper({status: true, result: resp.data});
        })
        .catch(function (error: any) {
            response = responseMapper({status: false, result: error});
        });
    return response;
}
