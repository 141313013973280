import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {Autocomplete, Divider, Stack, TextField} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {assignConsoleService, revokeConsoleService} from "./bulletin.service";
import {useLayoutContext} from "../../hooks/use-layout-context.hook";
import useLoader from "../../hooks/useLoader";


export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function ViewConsoles(props: any) {
    const {
        state: {consoles}
    } = useLayoutContext();
    const [loader, showLoader, hideLoader] = useLoader();
    const {open, setOpen, bulletin, loadBulletins} = props;
    const [consoleId, setConsoleId] = React.useState<string[]>([]);
    const [trigger, setTrigger] = React.useState<boolean>(false);

    let consoleIds: string[] = consoles.filter(function (el) {
        return bulletin?.consoleIds.indexOf(el) < 0;
    });

    const handleClose = () => {
        setOpen(false);
    };

    const assignConsole = async () => {
        if (consoleId) {
            showLoader();
            const response = await assignConsoleService(consoleId, bulletin.bulletin.bulletinId);
            if (response.status) {
                loadBulletins();
                // Append Console
                consoleId.forEach((value) => bulletin.consoleIds.push(value));
                setConsoleId([]);
                setTrigger(!trigger);
            } else {
                alert(response.result);
            }
            hideLoader();
        }
    };

    const deleteConsole = async (consoleId: string) => {
        showLoader();
        const response = await revokeConsoleService(consoleId, bulletin.bulletin.bulletinId);
        if (response.status) {
            let index = bulletin.consoleIds.indexOf(consoleId);
            if (index !== -1) {
                bulletin.consoleIds.splice(index, 1);
            }
            loadBulletins();
            setTrigger(!trigger);
        } else {
            alert(response.result);
        }
        hideLoader();
    };

    if (bulletin)
        return (
            <Dialog fullWidth onClose={handleClose} style={{}} open={open}>
                {loader}
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Bulletin: {bulletin.bulletin.bulletinId}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {consoleIds.length > 0 && (
                        <>
                            <Typography fontWeight={"bold"} style={{marginBottom: 10}}>
                                Add Console:
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Autocomplete
                                    sx={{width: 380}}
                                    freeSolo
                                    id="add-console-input"
                                    onChange={(evt: React.SyntheticEvent, value) => {
                                        setConsoleId(value);
                                    }}
                                    multiple
                                    disableClearable
                                    options={consoleIds}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search Console"
                                            value={consoleId}
                                            InputProps={{
                                                ...params.InputProps,
                                                type: "search"
                                            }}
                                        />
                                    )}
                                />
                                <Button onClick={assignConsole} variant="outlined">
                                    Add Console
                                </Button>
                            </Stack>
                        </>
                    )}
                    <Divider/>
                    <TableContainer sx={{maxHeight: 440}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align={"left"}>Console Ids</TableCell>
                                    <TableCell align={"center"}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bulletin.consoleIds.map((console: any, index: number) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            <TableCell align={"left"}>{console}</TableCell>
                                            <TableCell align={"center"}>
                                                <IconButton onClick={() => deleteConsole(console)} aria-label="delete"
                                                            color="primary">
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        );
    return null;
}
