import {ApiResponse} from "./api.functions";


export async function fetchCached(
    cacheKey: string, remoteApi: () => Promise<ApiResponse>, responseKey: string, forceFetch: boolean = false
): Promise<any | null> {
    let existingJson = window.localStorage.getItem(cacheKey);
    if (existingJson && !forceFetch) {
        return JSON.parse(existingJson);
    } else {
        const response = await remoteApi();
        if (response.status) {
            const value = Reflect.get(response.result, responseKey);
            if (value) {
                window.localStorage.setItem(cacheKey, JSON.stringify(value));
                return value;
            }
        }
    }
    return null;
}

export function clearCache() {
    window.localStorage.clear();
}

export function cachedValue(cacheKey: string): any | null {
    let existingJson = window.localStorage.getItem(cacheKey);
    if (existingJson) {
        return JSON.parse(existingJson);
    }
    return null;
}

export function updateCache(cacheKey: string, value: any) {
    window.localStorage.setItem(cacheKey, JSON.stringify(value));
}