import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import StickyHeadTable from "./components/tables.component";
import React, {useEffect, useState} from "react";
import {useLayoutContext} from "../../hooks/use-layout-context.hook";
import useLoader from "../../hooks/useLoader";
import {useNavigate} from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {fetchCached} from "../../libs/cache.functions";
import {getAllConsolesService} from "./consoles.service";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        padding: "0 80px"
    },
    title: {
        textAlign: "center"
    },
    session: {
        width: "80vw",
        overflow: "auto",
        overflowWrap: "break-word",
        fontSize: "16px"
    },
    hero: {
        width: "100%",
        background: "rgb(220,220,220)"
    }
}));

export default function Console() {
    const {dispatch} = useLayoutContext();
    const navigate = useNavigate();
    const [loader, showLoader, hideLoader] = useLoader();
    const [consoles, setConsoles] = useState<any>([]);
    const classes = useStyles();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 0) {
            navigate("/bulletin");
        } else if (newValue === 1) {
            navigate("/console");
        }
    };

    useEffect(() => {
        loadConsolesAndIds().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadConsolesAndIds = async (forceFetch: boolean = false) => {
        showLoader();
        let consoles = await fetchCached("consoles", getAllConsolesService, "consoles", forceFetch);
        if (!consoles) {
            consoles = [];
        }
        setConsoles(consoles);
        let consoleIds = consoles.reduce((a: string[], currentValue: any) => [...a, currentValue.consoleId], []);
        dispatch({type: "set-consoles", consoles: consoleIds});
        hideLoader();
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Tabs value={1} onChange={handleChange} centered>
                <Tab label="Bulletins"/>
                <Tab label="Consoles"/>
            </Tabs>
            {loader}
            <Grid className={classes.root} style={{marginTop: 20}}>
                <Stack direction="row" spacing={2} style={{marginBottom: 20}}>
                    <IconButton onClick={() => loadConsolesAndIds(true)} color="primary">
                        <AutorenewIcon/>
                    </IconButton>
                </Stack>
                <StickyHeadTable consoles={consoles} loadConsoles={loadConsolesAndIds}/>
            </Grid>
        </Grid>
    );
}
