import React from "react";


export interface ILayout {
    open: boolean;
    drawerwidth: number;
    title: string;
    consoles: string[];
}

export type LayoutActionOpen = {
    type: "set-drawer"
    open: boolean
}
export type LayoutActionTitle = {
    type: "set-title"
    title: string
}

export type LayoutConsoleList = {
    type: "set-consoles"
    consoles: string[]
}

export type LayoutAction = LayoutActionOpen | LayoutActionTitle | LayoutConsoleList


export type Context = {
    state: ILayout
    dispatch: (action: LayoutAction) => void
}


export const LayoutContext = React.createContext({} as Context);

export const drawerReducer = (state: ILayout, action: LayoutAction): ILayout => {
    switch (action.type) {
        case "set-drawer":
            return {
                ...state,
                open: action.open
            };
        case "set-title":
            return {
                ...state,
                title: action.title
            };
        case "set-consoles":
            return {
                ...state,
                consoles: action.consoles
            };
        default:
            throw new Error();
    }
};