import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import {criticality, instructionActions, instructionType, packageType} from "../enum";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import {Theme, useTheme} from "@mui/material";
import {Bulletin} from "./bulletin.types";
import {validateForm} from "./funcs";
import {addBulletinsService} from "./bulletin.service";
import useLoader from "../../hooks/useLoader";
import {cachedValue, updateCache} from "../../libs/cache.functions";


const defaultBulletins = {
    description: "",
    criticality: "",
    recordUserResponse: false,
    baseVersions: [
        {
            isRequired: false,
            partNumber: "",
            partRevision: "",
            packageType: packageType.SYSTEM,
            version: "",
            deleteBtn: false
        },
        {
            isRequired: false,
            partNumber: "",
            partRevision: "",
            packageType: packageType.BUSINESS_MODEL,
            version: "",
            deleteBtn: false
        },
        {
            isRequired: false,
            partNumber: "",
            partRevision: "",
            packageType: packageType.TREATMENT_LEVEL,
            version: "",
            deleteBtn: false
        },
        {
            isRequired: false,
            partNumber: "",
            partRevision: "",
            packageType: packageType.ACCESSORY,
            version: "",
            deleteBtn: false
        }
    ],
    instructions: [{message: "", type: "", actions: []}]
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
const AddBulletin: React.FunctionComponent = () => {
    const [formInputs, setFormInputs] = useState<Bulletin>(defaultBulletins);
    const [loader, showLoader, hideLoader] = useLoader();
    const [error, setError] = useState<string[]>([]);
    const navigate = useNavigate();

    const theme = useTheme();

    function getStyles(name: string, personName: readonly string[], theme: Theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
        };
    }

    function getStylesSingle(name: string, personName: string, theme: Theme) {
        return {
            fontWeight: personName === name ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
        };
    }

    const saveBulletin = async () => {
        const bulletin = {...formInputs};
        const errorMessage = validateForm(bulletin);
        bulletin.baseVersions = bulletin.baseVersions.filter(function (item) {
            return item.isRequired;
        });

        if (errorMessage.length > 0) {
            setError(errorMessage);
            return false;
        }

        showLoader();
        const response = await addBulletinsService(bulletin);
        if (response.status) {
            let cachedBulletins = cachedValue("bulletins");
            if (cachedBulletins){
                const updatedBulletins = [...cachedBulletins, {"bulletin": response.result.bulletin, "consoleIds": []}];
                updateCache("bulletins", updatedBulletins);
            }
            navigate("/bulletin");
        } else {
            alert(response.result);
        }
        hideLoader();
    };

    const addNewInstruction = () => {
        setFormInputs({
            ...formInputs,
            instructions: [...formInputs.instructions, {message: "", type: "", actions: []}]
        });
    };

    const deleteInstruction = (index: number) => {
        const temp = formInputs.instructions;
        temp.splice(index, 1);
        setFormInputs({...formInputs, instructions: temp});
    };

    const addNewBaseVersions = () => {
        setFormInputs({
            ...formInputs,
            baseVersions: [...formInputs.baseVersions, {
                isRequired: false,
                partNumber: "",
                partRevision: "",
                packageType: "",
                version: "",
                deleteBtn: false
            }]
        });
    };

    const deleteBaseVersions = (index: number) => {
        const temp = formInputs.baseVersions;
        temp.splice(index, 1);
        setFormInputs({...formInputs, baseVersions: temp});
    };

    const baseVersions = (
        <TableContainer component={Paper}>
            <Button onClick={addNewBaseVersions} color="primary" variant="contained">
                Add New
            </Button>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableBody>
                    {formInputs.baseVersions.map((row, index) => {
                        return (
                            <TableRow key={index} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                <TableCell component="th" scope="row">
                                    <Checkbox
                                        value={row.isRequired}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            let newArr = [...formInputs.baseVersions];
                                            newArr[index].isRequired = e.target.checked;
                                            setFormInputs({...formInputs, baseVersions: newArr});
                                        }}
                                    />
                                </TableCell>
                                <TableCell scope="row" sx={{m: 1, width: 250}}>
                                    <FormControl sx={{width: "100%"}}>
                                        <InputLabel id="single-packageType-label">Package Type</InputLabel>
                                        <Select
                                            label="Package Type"
                                            labelId="single-packageType-label"
                                            id="single-packageType"
                                            value={row.packageType}
                                            onChange={(evt: any) => {
                                                let newArr = [...formInputs.baseVersions];
                                                newArr[index].packageType = evt.target.value;
                                                setFormInputs({...formInputs, baseVersions: newArr});
                                            }}>
                                            {Object.values(packageType).map((name) => (
                                                <MenuItem key={name} value={name}
                                                          style={getStylesSingle(name, row.packageType, theme)}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell component="td" scope="row">
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label={"Part Number"}
                                        error={false}
                                        value={row.partNumber}
                                        onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            let newArr = [...formInputs.baseVersions];
                                            newArr[index].partNumber = evt.target.value;
                                            setFormInputs({...formInputs, baseVersions: newArr});
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label={"Part Revision"}
                                        error={false}
                                        value={row.partRevision}
                                        onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            let newArr = [...formInputs.baseVersions];
                                            newArr[index].partRevision = evt.target.value;
                                            setFormInputs({...formInputs, baseVersions: newArr});
                                        }}
                                    />
                                </TableCell>
                                {/* <TableCell align="right">
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={'Version'}
                    error={false}
                    value={row.version}
                    onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                      let newArr = [...formInputs.baseVersions]
                      newArr[index].version = evt.target.value
                      setFormInputs({ ...formInputs, baseVersions: newArr })
                    }}
                  />
                </TableCell> */}
                                <TableCell>
                                    {row.deleteBtn && (
                                        <Button onClick={() => deleteBaseVersions(index)} color="primary"
                                                variant="contained">
                                            Delete
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const instructions = (
        <TableContainer component={Paper}>
            <Button onClick={addNewInstruction} color="primary" variant="contained">
                Add New
            </Button>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableBody>
                    {formInputs.instructions.map((row, index) => {
                        return (
                            <TableRow key={index} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                <TableCell scope="row">
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label={"Message"}
                                        error={false}
                                        value={row.message}
                                        onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            let newArr = [...formInputs.instructions];
                                            newArr[index].message = evt.target.value;
                                            setFormInputs({...formInputs, instructions: newArr});
                                        }}
                                    />
                                </TableCell>
                                <TableCell scope="row" sx={{m: 1, width: 150}}>
                                    <FormControl sx={{m: 1, width: "100%"}}>
                                        <InputLabel id="single-type-label">Type</InputLabel>
                                        <Select
                                            label="Package Type"
                                            labelId="single-type-label"
                                            id="single-type"
                                            value={row.type}
                                            style={{width: "100%"}}
                                            onChange={(evt: any) => {
                                                let newArr = [...formInputs.instructions];
                                                newArr[index].type = evt.target.value;
                                                setFormInputs({...formInputs, instructions: newArr});
                                            }}>
                                            {Object.values(instructionType).map((name) => (
                                                <MenuItem key={name} value={name}
                                                          style={getStylesSingle(name, row.type, theme)}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell scope="row" sx={{m: 1, width: 300}}>
                                    <FormControl sx={{m: 1, width: "100%"}}>
                                        <InputLabel id="multiple-actions-label">Actions</InputLabel>
                                        <Select
                                            labelId="multiple-actions-label"
                                            id="multiple-actions"
                                            multiple
                                            value={row.actions}
                                            onChange={(evt: any) => {
                                                let newArr = [...formInputs.instructions];
                                                newArr[index].actions = evt.target.value;
                                                setFormInputs({...formInputs, instructions: newArr});
                                            }}
                                            input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
                                            renderValue={(selected) => (
                                                <Box sx={{display: "flex", flexWrap: "wrap"}} style={{gap: 0.5}}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value}/>
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}>
                                            {Object.values(instructionActions).map((name) => (
                                                <MenuItem key={name} value={name}
                                                          style={getStyles(name, row.actions, theme)}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    {/* {index > 0 && ( */}
                                    <Button onClick={() => deleteInstruction(index)} color="primary"
                                            variant="contained">
                                        Delete
                                    </Button>
                                    {/* )} */}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const addBulletinForm = (
        <div style={{width: "100%"}}>
            <Box width="80%" m={1}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label={"Description"}
                    value={formInputs.description}
                    error={false}
                    onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setFormInputs({...formInputs, description: evt.target.value});
                    }}
                />
            </Box>
            <Box
                width="50%"
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignSelf: "flex-start"
                }}>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id="multiple-criticality-label">Criticality</InputLabel>
                    <Select
                        label="Criticality"
                        labelId="multiple-criticality-label"
                        id="multiple-criticality"
                        value={formInputs.criticality}
                        onChange={(evt: any) => {
                            setFormInputs({...formInputs, criticality: evt.target.value});
                        }}>
                        {Object.values(criticality).map((name) => (
                            <MenuItem key={name} value={name}
                                      style={getStylesSingle(name, formInputs.criticality, theme)}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormGroup sx={{m: 1, width: "100%"}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={formInputs.recordUserResponse}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFormInputs({...formInputs, recordUserResponse: e.target.checked});
                                }}
                            />
                        }
                        label="Record User Response"
                    />
                </FormGroup>
            </Box>
            <Box width="80%" m={1}>
                <Typography
                    variant="h5"
                    component="h4"
                    style={{borderBottom: "1px black solid", marginBottom: 20, marginTop: 20}}>
                    Base Versions
                </Typography>
                {baseVersions}
            </Box>
            <Box width="80%" m={1}>
                <Typography
                    variant="h5"
                    component="h4"
                    style={{borderBottom: "1px black solid", marginBottom: 20, marginTop: 20}}>
                    Instructions
                </Typography>
                {instructions}
            </Box>

            {error.length > 0 && (
                <Box mt={2}>
                    <Typography color="error" variant="body2">
                        {error.map((s, index) => (
                            <React.Fragment key={index}>
                                {s}
                                <br/>
                            </React.Fragment>
                        ))}
                    </Typography>
                </Box>
            )}

            {/* Buttons */}
            <Box mt={2}>
                <Grid container direction="row" justifyContent="center">
                    <Box m={1}>
                        <Button onClick={() => navigate(-1)} color="secondary" variant="contained">
                            Cancel
                        </Button>
                    </Box>
                    <Box m={1}>
                        <Button onClick={saveBulletin} color="primary" variant="contained">
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </div>
    );

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
            {loader}
            <Grid xs={11} sm={6} lg={8} container direction="row" justifyContent="center" alignItems="center" item>
                <Paper style={{width: "100%", padding: 16}}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        {/* Title */}
                        <Box m={3}>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Typography variant="h3">Add Bulletin</Typography>
                            </Grid>
                        </Box>
                        {addBulletinForm}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AddBulletin;
