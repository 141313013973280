import * as React from "react";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {Divider, Paper} from "@mui/material";
import {Grid} from "@material-ui/core";
import {loadBulletinsService} from "./consoles.service";
import Button from "@mui/material/Button";
import CachedIcon from "@mui/icons-material/Cached";
import useLoader from "../../hooks/useLoader";
import {revokeConsoleService} from "../bulletin/bulletin.service";

// noinspection DuplicatedCode
const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function ViewBulletin(props: any) {
    const {open, setOpen, consoleId} = props;
    const [loader, showLoader, hideLoader] = useLoader();
    const [bulletins, setBulletins] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (open) loadBulletins().then();
        else {
            setLoading(true);
            setBulletins(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    async function loadBulletins() {
        showLoader();
        const response = await loadBulletinsService(consoleId);
        if (response.status) {
            setBulletins(response.result.bulletins[0].bulletins);
        } else {
            setBulletins([]);
        }
        setLoading(false);
        hideLoader();
    }

    const handleClose = () => {
        setOpen(false);
    };


    const deleteConsole = async (bulletinId: string) => {
        showLoader();
        const response = await revokeConsoleService(consoleId, bulletinId);
        if (response.status) {
            await loadBulletins();
        } else {
            alert(response.result);
        }
        hideLoader();
    };

    if (bulletins)
        return (
            <Dialog fullWidth onClose={handleClose} style={{}} open={open}>
                {loader}
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Console: {consoleId}'s Bulletins
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {bulletins.map((bulletin: any, index: number) => (
                        <div key={index}
                             style={index > 0 ? {paddingTop: 24, marginTop: 16, borderTop: "1px black solid"} : {}}>
                            <Button
                                variant="outlined"
                                onClick={() => deleteConsole(bulletin.bulletinId)}
                                color="error"
                                startIcon={<CachedIcon/>}>
                                Revoke
                            </Button>
                            <Typography fontWeight={"bold"}>Bulletin: {bulletin.bulletinId}</Typography>
                            <Typography fontWeight={"bold"}>Description:</Typography>
                            <Typography gutterBottom>{bulletin.description}</Typography>
                            <Divider/>
                            <Typography marginTop={2} fontWeight={"bold"}>
                                Criticality:
                            </Typography>
                            <Typography gutterBottom>{bulletin.criticality}</Typography>
                            <Divider/>
                            <Typography marginTop={2} fontWeight={"bold"}>
                                Record User Response:
                            </Typography>
                            <Typography gutterBottom>{bulletin.recordUserResponse ? "Yes" : "No"}</Typography>
                            <Divider/>
                            <Typography marginTop={2} fontWeight={"bold"}>
                                Base Versions:
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Item style={{backgroundColor: "#1976d2", color: "white", fontWeight: "bolder"}}>Part
                                        Number</Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item style={{backgroundColor: "#1976d2", color: "white", fontWeight: "bolder"}}>Package
                                        Type</Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item style={{backgroundColor: "#1976d2", color: "white", fontWeight: "bolder"}}>
                                        Part Revision
                                    </Item>
                                </Grid>
                            </Grid>
                            {bulletin.baseVersions.map((_obj: any, index: number) => (
                                <Grid key={index} container spacing={2}>
                                    <Grid item xs={4}>
                                        <Item>{_obj.partNumber}</Item>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Item>{_obj.packageType}</Item>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Item>{_obj.partRevision}</Item>
                                    </Grid>
                                </Grid>
                            ))}
                            <Typography marginTop={2} fontWeight={"bold"}>
                                Instructions:
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Item style={{
                                        backgroundColor: "#1976d2",
                                        color: "white",
                                        fontWeight: "bolder"
                                    }}>Message</Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item style={{
                                        backgroundColor: "#1976d2",
                                        color: "white",
                                        fontWeight: "bolder"
                                    }}>Type</Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item style={{
                                        backgroundColor: "#1976d2",
                                        color: "white",
                                        fontWeight: "bolder"
                                    }}>Actions</Item>
                                </Grid>
                            </Grid>
                            {bulletin.instructions.map((_obj: any, index: number) => (
                                <Grid key={index} container spacing={2}>
                                    <Grid item xs={4}>
                                        <Item>{_obj.message}</Item>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Item>{_obj.type}</Item>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Item>{_obj.actions.toString()}</Item>
                                    </Grid>
                                </Grid>
                            ))}
                        </div>
                    ))}
                    {loading && <Typography fontWeight={"bold"}>Loading...</Typography>}
                    {bulletins.length === 0 && !loading &&
                        <Typography fontWeight={"bold"}>No bulletins available</Typography>}
                </DialogContent>
            </Dialog>
        );
    return null;
}
