import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import StickyHeadTable from "./components/tables.component";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getAllBulletinsService} from "./bulletin.service";
import useLoader from "../../hooks/useLoader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {fetchCached} from "../../libs/cache.functions";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        padding: "0 80px"
    },
    title: {
        textAlign: "center"
    },
    session: {
        width: "80vw",
        overflow: "auto",
        overflowWrap: "break-word",
        fontSize: "16px"
    },
    hero: {
        width: "100%",
        background: "rgb(220,220,220)"
    }
}));

export default function Bulletin() {
    const [loader, showLoader, hideLoader] = useLoader();
    const [bulletins, setBulletins] = useState<any>([]);
    const classes = useStyles();
    const navigate = useNavigate();
    useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 0) {
            navigate("/bulletin");
        } else if (newValue === 1) {
            navigate("/console");
        }
    };

    useEffect(() => {
        loadBulletins().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadBulletins = async (forceFetch: boolean = false) => {
        showLoader();
        let bulletins = await fetchCached("bulletins", getAllBulletinsService, "bulletins", forceFetch);
        if (!bulletins) {
            bulletins = [];
        }
        setBulletins(bulletins);
        hideLoader();
    };
    return (
        <Grid container justifyContent="center" alignItems="center">
            {loader}
            <Tabs value={0} onChange={handleChange} centered>
                <Tab label="Bulletins"/>
                <Tab label="Consoles"/>
            </Tabs>
            <Grid className={classes.root}>
                <Stack direction="row" spacing={2} style={{marginBottom: 20}}>
                    <Button variant="outlined" onClick={() => navigate("addBulletin")} color="primary">
                        Add Bulletin
                    </Button>
                    <IconButton onClick={() => loadBulletins(true)} color="primary">
                        <AutorenewIcon/>
                    </IconButton>
                </Stack>
                <StickyHeadTable bulletins={bulletins} loadBulletins={loadBulletins}/>
            </Grid>
        </Grid>
    );
}
