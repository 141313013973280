import {callRestApi} from "../../libs/api.functions";
import {Bulletin} from "./bulletin.types";


export const getAllBulletinsService = async () => {
    let data = JSON.stringify({
        bulletinIds: ["ALL"]
    });
    return await callRestApi("web/v1/updateBulletin/get", data);
};

export const addBulletinsService = async (bulletin: Bulletin) => {
    const data = JSON.stringify({
        bulletin: bulletin,
    });
    return await callRestApi("web/v1/updateBulletin/create", data);
};

export const deleteBulletinService = async (bulletinId: string) => {
    let data = JSON.stringify({
        bulletinId: bulletinId,
    });
    return await callRestApi("web/v1/updateBulletin/delete", data);
};

export const assignConsoleService = async (consoleIds: string[], bulletinId: string) => {
    let data = JSON.stringify({
        bulletinId: bulletinId,
        consoleIds: consoleIds,
    });
    return await callRestApi("web/v1/updateBulletin/assign", data);
};

export const revokeConsoleService = async (consoleId: string, bulletinId: string) => {
    let data = JSON.stringify({
        bulletinId: bulletinId,
        consoleIds: [consoleId]
    });
    return await callRestApi("web/v1/updateBulletin/revoke", data);
};