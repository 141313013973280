import {callRestApi} from "../../libs/api.functions";


export const getAllConsolesService = async () => {
    const data = JSON.stringify({
        consoleIds: ["ALL"]
    });
    return await callRestApi("web/v1/console/get", data);
};

export const loadBulletinsService = async (consoleId: string) => {
    const data = JSON.stringify({
        consoleIds: [consoleId]
    });
    return await callRestApi("web/v1/updateBulletin/list", data);
};